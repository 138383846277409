import React from 'react';
import Card from './Card';

const Conversation = ({
  conversation,
  onSendMessage,
  currentId,
}) => {
  const buttonOptions = conversation.config?.buttons || []

  return (
    <div className="conversation" id={currentId}>
      <div className="cards-container space-y-4">
        {/* Render all cards in the conversation */}
        <Card
          key={conversation.id}
          card={conversation}
          options={buttonOptions}
          isUser={conversation.role === 'user'}
          onSendMessage={message => onSendMessage(message, conversation)}
        />
      </div>
      {/* Render branches if they exist */}
      {conversation.branches && (
        <div className="branches mt-4 flex space-x-4">
          {conversation.branches.map((branch, branchIndex) => {
            return (
              <Conversation
                key={branchIndex}
                currentId={branch.id}
                conversation={branch}
                onSendMessage={onSendMessage}
                baseIndex={branchIndex}
              />
            )
          })}
        </div>
      )}
    </div>
  );
};

export default Conversation;
