import React from 'react';
import Conversation from './Conversation';

// Chat component to render all conversations
const Chat = ({ conversations = [], onSendMessage, loadingProject }) => {
  return (
    <div className="chat flex flex-wrap">
      {conversations.map((conversation, index) => (
        <div key={index} className="conversation-container px-2">
          {/* Render each conversation */}
          <Conversation
            conversation={conversation}
            onSendMessage={onSendMessage}
          />
        </div>
      ))}
    </div>
  );
}

export default Chat;
