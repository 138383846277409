import React, { useRef } from 'react';
import Chat from './Chat';
import useBranchyStore from '../../../hooks/store/useBranchyStore';
import Loader from '../../../components/loader'

export default function ChatComponent  ()  {
  const chatEndRef = useRef(null);
  const {
    currentProject,
    loadingProject,
    submiting,
    onMessageToApi
  } = useBranchyStore();

  if (loadingProject || submiting) {
    return (
      <Loader
        style={{ marginTop: 80, zIndex: 500, backdropFilter: 'blur(12px)' }}
      />
    )
  }
  return (
    <div className="chat-container overflow-auto flex flex-col !py-4 bg-custom-black min-h-screen">
      {currentProject.length > 0 && (
        <Chat
          conversations={currentProject}
          onSendMessage={onMessageToApi}
        />
      )}
      <div ref={chatEndRef} />
    </div>
  );
};
