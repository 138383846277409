import React, { useState, useRef, useEffect, Fragment } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import { marked } from 'marked';
import { htmlToText } from 'html-to-text';
import pinnAvatar from '../../../assests/img/pinn_avatar.png';
import userStaticAvatar from '../../../assests/img/user_avatar.png';
import sendIcon from '../../../assests/img/send.png';
import copyIcon from '../../../assests/img/copy.png';
import checkmarkIcon from '../../../assests/img/checkmark.png';
import { getCleanText, getImageUrl, parseContentCitations } from '../utils/cardUtils';
import Storage from 'localStorage';
import { defaultAvatar, isValidUrl } from '../../../utils/Utils';
import useAddFile from '../hooks/useAddFile';

const Card = ({
  card,
  isUser,
  onSendMessage,
  onReadPDF,
  options
}) => {
  const [input, setInput] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [placeholderText, setPlaceholderText] = useState('.');

  const { onChangeFile, isSent, isloading } = useAddFile();

  // Refs for the card and textarea elements
  const textareaRef = useRef(null);

  // Effect for animating the loading placeholder
  useEffect(() => {
    if (!isUser && card.content === '...') {
      const interval = setInterval(() => {
        setPlaceholderText((prev) => {
          if (prev === '.') return '..';
          if (prev === '..') return '...';
          return '.';
        });
      }, 500);

      return () => clearInterval(interval);
    }
  }, [isUser, card.content]);

  // Effect for focusing and scrolling to the newest card
  useEffect(() => {
    const localRecentCard = Storage.getItem('recent-branchy') || 0;
    if (textareaRef.current && (Number(localRecentCard) === card.id || localRecentCard === 0)) {
      textareaRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      setTimeout(() => {
        textareaRef.current?.focus();
      }, 600);
    }
  }, []);

  // Handler for input changes
  const handleInputChange = (event) => {
    setInput(event.target.value);
    adjustTextareaHeight();
  };

  // Function to adjust textarea height based on content
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  // Handler for submitting a message
  const handleSubmit = () => {
    if (input.trim()) {
      onSendMessage(input);
      setInput('');
    }
  };

  // Handler for keyboard shortcuts
  const handleKeyDown = (event) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      handleSubmit();
    }
  };

  const isInputEmpty = input.trim().length === 0;

  // Handler for reading a PDF file
  const handleReadPDF = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.pdf';
    input.onchange = (event) => {
      const file = event.files?.[0];
      if (file) {
        onReadPDF(file);
      }
    };
    input.click();
  };

  // Function to render action buttons
  const renderButtons = () => {
    return options?.map((option, index) => (
      <Fragment key={index}>
        {option.label.toLowerCase().includes('pdf') && !hasPdfFile ? (
          <label
            className={
              `flex items-center cursor-pointer mb-0 rounded-[4px] border !border-seenapse-green px-2 h-[25.5px]
                ${isSent || isloading ? '!cursor-not-allowed !opacity-50 !pointer-events-none' : ''}
              `}
          >
            <span className="text-custom-small font-semibold text-seenapse-green uppercase">{option.label}</span>
            <input type="file" accept='.pdf' className="hidden" onChange={(event) => onChangeFile(event, card.id)} />
          </label>
        ) : !option.label.toLowerCase().includes('pdf') && (
          <button
            onClick={() => onSendMessage(option.action)}
            className="!px-1 !py-1 !text-custom-small !text-seenapse-green !border !border-seenapse-green !bg-transparent rounded hover:opacity-80 uppercase"
          >
            {option.label}
          </button>
        )}
      </Fragment>
    ));
  };

  // Handler for copying card content
  const handleCopy = () => {
    const plainTextPromise = marked(card.content, { renderer: new marked.Renderer() });
    Promise.resolve(plainTextPromise)
      .then(plainText => {
        const text = htmlToText(plainText, {
          wordwrap: false,
          preserveNewlines: true
        });
        navigator.clipboard.writeText(text)
          .then(() => {
            console.log('Text copied to clipboard');
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
          })
          .catch(err => {
            console.error('Could not copy text: ', err);
          });
      })
      .catch(err => {
        console.error('Error processing markdown:', err);
      });
  };

  const parseContent = card.type === 'image'
    ? `${getCleanText(card.content)} ${getImageUrl(card.content)}`
    : parseContentCitations({
      content: card.content,
      citations: card.config?.citations || []
    });


  const userAvatarDefault = Storage.getItem('avatar') || userStaticAvatar;
  const avatar = card.user && isValidUrl(card.user.avatar) ? card.user.avatar : userAvatarDefault;
  const hasPdfFile = card.branches.some(branch => branch.type === 'pdf');

  return (
    <div
      className="relative group flex mb-4 w-card bg-card-bg text-white font-sans shadow-lg !p-3 rounded-lg"
      id={`card-${card.id}`}
    >
      {/* Copy button */}
      <div className="absolute top-2 right-2 flex space-x-1 opacity-0 group-hover:opacity-100 transition-opacity">
        <button
          className="w-7 h-7 bg-seenapse-green rounded-full flex items-center justify-center"
          onClick={handleCopy}
        >
          <img src={isCopied ? checkmarkIcon : copyIcon} alt="copy" className="w-4 h-4" />
        </button>
      </div>
      {/* Avatar */}
      <img
        alt="avatar"
        src={isUser ? avatar : pinnAvatar}
        onError={e => defaultAvatar(e, avatar)}
        className="w-7 h-7 rounded-full !mr-3 flex-none"
      />
      <div className="flex-1">
        {/* Card content */}
        <div className="content text-custom-mid mb-2 [&_img]:mt-4">
          {isUser ? (
            <span>{card.content}</span>
          ) : (
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  a: ({ node, ...props }) => (
                    <a {...props}  className="react-markdown"  target="_blank" rel="noopener noreferrer" />
                  )
                }}
              >
              {card.content === '...' ? placeholderText : parseContent}
            </ReactMarkdown>
          )}
        </div>

        {!isUser && (
          <>
            {/* Action buttons */}
            <div className="actions flex justify-start mt-1 space-x-2">
              {renderButtons()}
            </div>
            {/* Input area */}
            <div className="input-area !mt-4 flex items-center !border !border-card-bg rounded">
              <textarea
                ref={textareaRef}
                className="flex-grow p-2 bg-textarea-bg text-white rounded-md resize-none focus:outline-none text-custom-mid"
                placeholder="Chat..."
                value={input}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                rows={1}
              />
              <button
                onClick={handleSubmit}
                className={`flex-none w-8 h-8 ml-1 flex items-center justify-center rounded-full hover:opacity-80 ${isInputEmpty ? 'bg-textarea-bg' : 'bg-seenapse-green'
                  }`}
                style={{ marginLeft: '5px' }}
                disabled={isInputEmpty}
              >
                <img src={sendIcon} alt="send" className="w-4 h-4" />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
